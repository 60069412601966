import React from "react";
import { Link } from 'gatsby'
import appData from "data/app.json";

const Footer = ({ hideBGCOLOR }) => {
  return (
    <footer className={`${!hideBGCOLOR ? "sub-bg" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="item md-mb50">
              <div className="title">
                <h5>Contact Us</h5>
              </div>
              <ul>
                <li>
                  <span className="icon pe-7s-mail"></span>
                  <div className="cont">
                    <h6>Email Us</h6>
                    <p>contact@revbull.io</p>
                  </div>
                </li>
              </ul>
              <ul>
                <li>
                  <span className="icon pe-7s-phone"></span>
                  <div className="cont">
                    <h6>Call Us</h6>
                    <p>781-607-4621</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="item">
              <div className="logo">
                <img src={appData.lightLogo} alt="" />
              </div>
              <div className="copy-right">
                <p>
                  © 2023, MADE BY REVBULL
                  <Link to="https://www.revbull.io" target="_blank">

                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;